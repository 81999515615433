import React from "react"
import { Link, graphql } from "gatsby"
import { Routes } from "../../data/routes"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Content = {
  pageName: "Blog",
  description: "Yup Blog",
  header: "Yup Blog",
}

type BlogEntry = {
  excerpt: string
  fields: {
    slug: string
  }
  frontmatter: {
    date: string
    title: string
    description: string | null
    coverImage: any
    categories: Array<string>
  }
}

function BlogEntry(props: { post: BlogEntry }) {
  const categoryLabel =
    props.post.frontmatter.categories[0]?.replace(/-/g, " ") ?? ""
  const image = getImage(props.post.frontmatter.coverImage)
  return (
    <Link
      to={props.post.fields.slug}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
    >
      <div className="flex-shrink-0">
        {image && (
          <GatsbyImage
            className="h-48 w-full object-cover"
            image={image}
            alt={props.post.frontmatter.title}
          />
        )}
      </div>
      <div className="flex-1 pt-4 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm text-blue uppercase font-bold tracking-widest">
            {categoryLabel}
          </p>
          <p className="text-xl font-semibold text-gray-900">
            {props.post.frontmatter.title}
          </p>
          <p className="mt-3 text-base text-gray-500">{props.post.excerpt}</p>
        </div>
        <div className="mt-6 text-sm text-gray-500">
          {props.post.frontmatter.date}
        </div>
      </div>
    </Link>
  )
}

function BlogSection(props: { posts: Array<BlogEntry> }) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/blog-sections#component-720cf60b960fecb99c45f462f24db2d9
  return (
    <div className="max-w-7xl mx-auto relative pt-4 pb-20 px-4 sm:px-6 lg:px-8">
      <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {props.posts.map((post: BlogEntry) => (
          <BlogEntry key={post.frontmatter.title} post={post} />
        ))}
      </div>
    </div>
  )
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/header#component-2c3b25e7b9e4490edd7b6950692c0a11
  return (
    <div className="bg-secondary">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:py-24 sm:px-8 lg:px-8">
        <div className="text-center">
          <h1 className="mt-1 text-3xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-5xl">
            {Content.header}
          </h1>
        </div>
      </div>
    </div>
  )
}

const Blog = (props: { data: any }) => {
  const posts = props.data.allMarkdownRemark.nodes
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.blog}
      />
      <Header />
      <BlogSection posts={posts} />
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "blog" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          categories
          coverImage {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
  }
`
